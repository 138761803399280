import React  from 'react';
import {FaRegUser} from "react-icons/fa";
import {MdMailOutline} from "react-icons/md";
import useSalesRep from "./useSalesRep";
import TitleAndSubTitle from "../TitleAndSubTitle";
import './SalesRep.css'





export default function SalesRep() {
    // États pour la gestion des données du dashboard
    const [{sale }] = useSalesRep();
    console.log("Sale information:", sale);
    return (
        <main className="sales-rep">
            <TitleAndSubTitle h1="Votre contact Virteem Companion" h2="Contactez votre Référent Virteem Companion"/>
            <br/>

            <p>Si vous avez des questions ou si vous avez besoin
                d'assistance, n'hésitez pas à contacter votre interlocuteur chez Virteem.
            </p>
            <p>Nous sommes là pour vous aider !</p>
            <br/>

            <hr/>

            <div className='flex gap-4  flex-wrap flex-row items-center justify-center	'>
                <img src='/images/pa.png' className='sales-rep-img rounded-full' alt={"Image de votre référent virteem companion"}/>
                <div className='flex-wrap flex-col space-y-4'>
                    <div className='flex items-center gap-2'>
                        <FaRegUser className='w-5 h-5 text-black'/>
                        <div className='font-semibold'>{`${sale?.first_name} ${sale?.last_name}`}</div>
                    </div>
                    <div className='flex items-center gap-2'>
                        <MdMailOutline className='w-5 h-5 text-black'/>
                        <a href={`mailto:${sale?.email}`} className='font-semibold'>{sale?.email}</a>
                    </div>
                </div>
            </div>
            <br/>

        </main>
    );
}
