import React, { useState, useRef, useEffect } from 'react';
import { useAppContext } from '../../../context/Context';
import { FaCalendarAlt, FaCalendarDay, FaCalendarWeek, FaCalendarTimes, FaCalendarCheck, FaCalendarPlus } from 'react-icons/fa';
import { startOfMonth, endOfMonth, addDays } from 'date-fns';

const TimeRangeSelector = ({ onDateChange }) => {
  const [{ user }] = useAppContext();

  // Date de création de l'entreprise, utilisée pour l'option "Tout"
  const companyCreationDate = new Date(user.company.created_at);

  // Date d'aujourd'hui
  const today = new Date();

  // États pour gérer les dates, la sélection et l'affichage du sélecteur
  const [selectedOption, setSelectedOption] = useState('thisMonth');  // Option sélectionnée par défaut
  const [customLabel, setCustomLabel] = useState('');  // Label pour la date custom
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [showOptions, setShowOptions] = useState(false);  // Contrôle de l'affichage des options
  const [showDatePicker, setShowDatePicker] = useState(false);  // Contrôle de l'affichage du sélecteur
  const datePickerRef = useRef(null);  // Référence pour gérer les clics en dehors du sélecteur
  const buttonRef = useRef(null);  // Référence pour le bouton principal

  // Options de plage de dates avec icônes
  const options = [
    { value: 'all', label: 'Tout', icon: <FaCalendarAlt /> },
    { value: 'today', label: "Aujourd'hui", icon: <FaCalendarDay /> },
    { value: 'last7Days', label: '7 derniers jours', icon: <FaCalendarWeek /> },
    { value: 'last30Days', label: '30 derniers jours', icon: <FaCalendarTimes /> },
    { value: 'thisMonth', label: 'Ce mois', icon: <FaCalendarCheck /> },
    { value: 'last3Months', label: '3 derniers mois', icon: <FaCalendarAlt /> },
    { value: 'custom', label: customLabel || 'Plus...', icon: <FaCalendarPlus /> }  // Custom date range label
  ];

  // Fonction pour formater les dates au format ISO avec heure
  const formatDate = (date, isStart = true) => {
    const isoString = date.toISOString();
    return isStart ? `${isoString.split('T')[0]}T00:00:00` : `${isoString.split('T')[0]}T23:59:59.026Z`;
  };



// Fonction pour obtenir le jour suivant le premier jour du mois
const getFirstDayOfMonth = (date: Date): Date => {
  const firstDay = startOfMonth(date);
  const nextDay = addDays(firstDay, 1); // Ajouter 1 jour au premier jour du mois
  console.log("Premier jour du mois + 1:", nextDay);
  return nextDay; // Renvoie un objet Date pour le jour suivant le premier jour du mois
};
  // Fonction pour obtenir le dernier jour du mois
  const getLastDayOfMonth = (date: Date): Date => {
    console.log("Dernier jour du mois:", endOfMonth(date));
    return endOfMonth(date); // Renvoie un objet Date pour le dernier jour du mois
  };

  // Fonction pour calculer la date il y a n jours
  const getDaysAgo = (days) => {
    const date = new Date(today);
    date.setDate(today.getDate() - days);
    return date;
  };

  // Gestion du changement de sélection
  const handleChange = (value) => {
    setSelectedOption(value);

    if (value === 'custom') {
      setShowDatePicker(true);
      setShowOptions(false); // Masquer les options lorsque le sélecteur de date s'affiche
    } else {
      setShowDatePicker(false);
      handlePresetDateSelection(value);
    }
  };

  // Gère la sélection de dates prédéfinies
  const handlePresetDateSelection = (value) => {
    let start = '';
    let end = formatDate(today, false);

    switch (value) {
      case 'today':
        start = formatDate(today);
        end = formatDate(today, false);
        break;
      case 'last7Days':
        start = formatDate(getDaysAgo(6)); // 7 derniers jours, incluant aujourd'hui
        break;
      case 'last30Days':
        start = formatDate(getDaysAgo(29)); // 30 derniers jours, incluant aujourd'hui
        break;
      case 'thisMonth':
        start = formatDate(getFirstDayOfMonth(today));  // Début du mois : 01/mois en cours
        end = formatDate(getLastDayOfMonth(today), false); // Fin du mois
        break;
      case 'last3Months':
        const threeMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 2, 1);
        start = formatDate(getFirstDayOfMonth(threeMonthsAgo));
        end = formatDate(getLastDayOfMonth(today), false);
        break;
      case 'all':
        start = formatDate(companyCreationDate);
        break;
      default:
        start = '';
        end = '';
    }

    setStartDate(start);
    setEndDate(end);
    onDateChange(start, end);
    setShowOptions(false); // Masquer les options après la sélection
  };

  // Gère les dates personnalisées
  const handleDateChange = (start, end) => {
    const formattedStart = formatDate(new Date(start));
    const formattedEnd = formatDate(new Date(end), false);
    setStartDate(formattedStart);
    setEndDate(formattedEnd);
    const label = `${start.split("-").reverse().join("-")} - ${end.split("-").reverse().join("-")}`;
    setCustomLabel(label);
    setSelectedOption('custom');
    setShowDatePicker(false);
    onDateChange(formattedStart, formattedEnd);
  };


  // Ferme les options si on clique en dehors
  const handleClickOutside = (event) => {
    if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
      setShowDatePicker(false);
      setShowOptions(false);
    }
  };

  // Attache l'écouteur d'événements pour détecter les clics en dehors des options
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Initialise la plage de dates par défaut
  useEffect(() => {
    handlePresetDateSelection('thisMonth');
  }, []);

  // Vérifie si la plage de dates personnalisée est valide
  const isInvalidDateRange = () => {
    return new Date(startDate) > new Date(endDate);
  };

  return (
    <div className="relative flex flex-col items-end mb-6">
      <div className="w-full max-w-xs">
        {/* Bouton principal qui affiche l'option sélectionnée */}
        <button
          ref={buttonRef}
          className="w-full p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-virteem-darkBlueLight text-black text-sm flex justify-between items-center"
          onClick={() => setShowOptions(!showOptions)}
        >
          <span className="flex items-center gap-2">
            {options.find(option => option.value === selectedOption)?.icon}
            {options.find(option => option.value === selectedOption)?.label}
          </span>
          <svg
            className={`w-4 h-4 transform ${showOptions ? 'rotate-180' : ''}`}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
          </svg>
        </button>

        {/* Liste des options affichées comme boutons lorsque showOptions est true */}
        {showOptions && (
          <div
            className="absolute top-full right-0 z-10 bg-white border border-gray-300 rounded-md shadow-lg mt-2"
            style={{ width: buttonRef.current ? `${buttonRef.current.offsetWidth}px` : 'auto' }}
          >
            {options.map((option) => (
              <button
                key={option.value}
                className={`w-full text-left px-4 py-2 text-sm flex items-center gap-2 focus:outline-none ${
                  selectedOption === option.value ? 'bg-perso-blue text-white' : 'hover:bg-gray-100 text-gray-900'
                }`}
                onClick={() => handleChange(option.value)}
              >
                {option.icon}
                {option.label}
              </button>
            ))}
          </div>
        )}

        {/* Sélecteur de dates personnalisé */}
        {showDatePicker && (
          <div
            ref={datePickerRef}
            className="absolute top-full right-0 z-10 bg-white border border-gray-300 rounded-md shadow-lg p-4 mt-2"
            style={{ width: buttonRef.current ? `${buttonRef.current.offsetWidth}px` : 'auto' }}
          >
            <label className="block text-xs font-medium text-gray-700">
              Date de début :
              <input
                type="date"
                value={startDate.split('T')[0]}
                onChange={(e) => setStartDate(e.target.value)}
                min={companyCreationDate.toISOString().split('T')[0]}
                max={new Date().toISOString().split('T')[0]}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-virteem-darkBlueLight text-sm"
              />
            </label>
            <label className="block mt-4 text-xs font-medium text-gray-700">
              Date de fin :
              <input
                type="date"
                value={endDate.split('T')[0]}
                onChange={(e) => setEndDate(e.target.value)}
                min={companyCreationDate.toISOString().split('T')[0]}
                max={new Date().toISOString().split('T')[0]}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-virteem-darkBlueLight text-sm"
              />
            </label>
            <button
              onClick={() => handleDateChange(startDate.split('T')[0], endDate.split('T')[0])}
              disabled={isInvalidDateRange()}
              className={`mt-4 w-full py-2 px-4 rounded-md text-sm font-semibold ${
                isInvalidDateRange()
                  ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                  : 'bg-perso-blue text-white hover:bg-opacity-75'
              }`}
            >
              Valider
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default TimeRangeSelector;
