import { useEffect, useState } from "react";


import {useAppContext} from "../../../../context/Context";
import api from "../../../../utils/api";

export default function useSalesRep() {
    const [{ user }] = useAppContext(); // Récupération des informations utilisateur depuis le contexte global de l'application
    const [sale, setSale] = useState(null);             // Stocke les informations du commercial

    // Fonction d'initialisation qui fait les appels API nécessaires pour obtenir les données du tableau de bord
    const init = async () => {
        try {

            // Appel à l'API pour récupérer les informations du commercial associé à la société de l'utilisateur
            console.log(`Fetching sales representative data for company_id=${user.company.salesrep_id}`);
            const saleResponse = await api.get(`/salesrep/${user.company.salesrep_id}`);
            console.log("Sales representative data fetched:", saleResponse.data);
            setSale(saleResponse.data); // Mise à jour de l'état avec les données du commercial


        } catch (error) {
            console.error("Error fetching dashboard data:", error); // Gestion des erreurs d'API
        }
    };
    // Effet qui s'exécute lorsque les valeurs `user.company_id`, `startDate`, ou `endDate` changent
    useEffect(() => {
        if (user?.company_id ) {
            console.log("Triggering data fetch with:", {
                company_id: user.company_id,

            });
            init(); // Initialisation des données à chaque changement de l'utilisateur ou des dates
        }
    }, [user?.company_id]);

    // Retour d'un tableau contenant un seul objet avec toutes les données nécessaires pour le tableau de bord
    return [{
        sale              // Informations sur le commercial
    }];
}
